import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Session} from 'bc-react-session';


const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ChangePassword extends Component {

    

    constructor(props) {
        super(props);
        const session = Session.get();

        // console.log(session.isValid); // will be true if is not expired or innactive
        // console.log(payload); // anything you have set on the session payload is stored here

        if (!session || !session.isValid) {
          this.props.history.push("/");
        }


        this.state = {
          email: "",
          old_password: "",
          new_password: "",
          open: false,
          message: ""
        };

        
      }

    handleSubmit = (event) => {
        event.preventDefault();
        let data = {
            method: 'POST',
            body: JSON.stringify({
                email_address: this.state.email,
                new_password: this.state.new_password,
                old_password: this.state.old_password
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        console.log("Fetching");
        fetch(process.env.REACT_APP_API_URL + "/userauth/changepassword", data)
        .then((response) => {
            if(!response.ok) {
              this.setState({
                open: true,
                message: "Failed to change password."
              });
                throw Error(response.statusText);
            } 
            return response.json();
        })
        .then((responseJson) => {

                Session.destroy();
                this.setState({
                  open: true,
                  message: "Password was changed successfully, please log in again."
                },()=> {
                  setTimeout(()=>this.props.history.push('/'), 3000);
                })
                
                
            })
        .catch((error) => {
            console.error(error);
        });
    }
  
    handleChange = event => {
      this.setState({
        [event.target.name]: event.target.value
      });
    };

    handleClick = () => {
      this.setState({
        open: true
      });
    };
  
    handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      this.setState({
        open: false
      });
    };
   

  render() {
    const {classes} = this.props;
    const { email, old_password, new_password} = this.state;

    return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
        <div className={classes.paper}>
            <Avatar className={classes.avatar} color='primary'>
            <LockOpenIcon color='tertiary'/>
            </Avatar>
            <Typography component="h1" variant="h5">
            Change Your Password
            </Typography>
            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
            <TextField
               
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Confirm Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={this.handleChange}
                
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="old_password"
                label="Old Password"
                type="password"
                id="old_password"
                autoComplete="old-password"
                value={old_password}
                onChange={this.handleChange}

            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="new_password"
                label="New Password"
                type="password"
                id="new_password"
                autoComplete="new-password"
                value={new_password}
                onChange={this.handleChange}

            />
            
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
            >
                Change Password
            </Button>
            
            </form>
        </div>

        </Container>
    );
    }
}

export default withStyles(useStyles)(ChangePassword)