import React, {Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Pagination from "material-ui-flat-pagination";
import {Session} from 'bc-react-session';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles(theme => ({
    hroot: {
      flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1, 2),
      },

      formControl: {
        padding: theme.spacing(1, 2),

        minWidth: 300,
      },
      inputLabel: {
        marginLeft: '0.5rem',
      },
    
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    root: {
      padding: theme.spacing(3, 2),
    },
    searchroot: {
        margin: theme.spacing(2, 2),
        padding: theme.spacing(1, 1),
    display: 'flex',
    alignItems: 'center',
      },
    chip: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    card: {
      minWidth: 275,
      margin: theme.spacing(2,2),
    },
    input: {
        width: '100%',
    },
    flag: {
        width: "40px",
        height: "40px",
        float: "right",
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    cardTitle: {
      fontSize: 12,
    },
    pos: {
      marginBottom: 12,
    },
    RedStyle: {
      backgroundColor: '#d71f16 !important'
    },
    GreenStyle: {
      backgroundColor: '#26b907 !important',
      "& .MuiLinearProgress-bar": {
        backgroundColor: '#26b907 !important'
      }
    },
    NormalStyle: {
      backgroundColor: 'rgb(182, 188, 226) !important',
      "& .MuiLinearProgress-bar": {
        backgroundColor: '#3f51b5 !important'
      }
    },
    RedStyleChip: {
      backgroundColor: '#d71f16 !important'
    },
    GreenStyleChip: {
      backgroundColor: '#26b907 !important'
    },
    NormalStyleChip: {
      backgroundColor: '#3f51b5 !important'
    }
  }));

  

  function CustomizedInputBase(props) {
    const classes = useStyles();

  
    return (
      <Paper className={classes.searchroot}>
        <InputBase
          onChange={props.handleInput}
          className={classes.input}
          onKeyUp={props.handleKeyUp}
          placeholder="Search Orders"
          inputProps={{ 'aria-label': 'search orders' }}
        />
        <IconButton className={classes.iconButton} aria-label="search" onClick={props.handleSearch}>
          <SearchIcon />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
      </Paper>
    );
  }

  function SortSelector(props) {
    const classes = useStyles();
    return (

  <FormControl variant="filled" className={classes.formControl}>
  <InputLabel id="demo-simple-select-filled-label" className={classes.inputLabel}>Sort by</InputLabel>
  <Select
    id="demo-simple-select-filled"
    value={props.sort_option}
    onChange={props.handleSort}
  >
    <MenuItem value="last_updated">Update Date</MenuItem>
    <MenuItem value="order_reference">Order Reference</MenuItem>
  </Select>
</FormControl>

    );
  }


function OrderCard(props) {
    const order = props.order;
    const classes = useStyles();
    var statusBasedStyle;
    var chipBasedStyle;
    switch (order.summary.status_percentage) {
      case 0:
        statusBasedStyle = classes.RedStyle;
        chipBasedStyle = classes.RedStyleChip;
        break;
      case 16:
        statusBasedStyle = classes.NormalStyle;
        chipBasedStyle = classes.NormalStyleChip;
        break;
      case 33:
        statusBasedStyle = classes.NormalStyle;
        chipBasedStyle = classes.NormalStyleChip;
        break;
      case 50:
        statusBasedStyle = classes.NormalStyle;
        chipBasedStyle = classes.NormalStyleChip;
        break;
      case 67:
        statusBasedStyle = classes.NormalStyle;
        chipBasedStyle = classes.NormalStyleChip;
        break;
      case 83:
        statusBasedStyle = classes.NormalStyle;
        chipBasedStyle = classes.NormalStyleChip;
        break;
      case 100:
        statusBasedStyle = classes.GreenStyle;
        chipBasedStyle = classes.GreenStyleChip;
        break;
      default:
        statusBasedStyle = classes.NormalStyle;
        chipBasedStyle = classes.NormalStyleChip;
        break;
    }

    return (
        <Card className={classes.card}>
                <CardContent>
                <LinearProgress className={statusBasedStyle} variant="determinate" value={order.summary.status_percentage} />
                <br />
                  <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                  {order.summary.last_updated} <span className={`${classes.flag} flag-icon flag-icon-${order.summary.country_code.toLowerCase()}`} ></span>
                  </Typography>
                  <Typography variant="h5" component="h2">
                  {order.summary.product}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {order.summary.customer_order_ref} ({order.summary.internal_order_ref})
                    <br/>
                    <strong>Supplier: {order.summary.supplier}</strong>
                    <br/>
                    <strong>Importer: {order.summary.importer}</strong>
                  </Typography>
                  <Chip
                  size="small"
                  color="primary"        
                  label={order.summary.status}
                  
                  className={`${classes.chip} ${chipBasedStyle}`}
                />
                  <Typography variant="body2" component="p">
                  {order.summary.eta.split('\n').map((item, key) => {
                      return <span key={key}>{item}<br/></span>
                  })}

                  </Typography>
                  
                </CardContent>
              </Card>
    )
}

function OrderCards(props) {
    const orders = props.orders;
    return (
        <div>
             <Grid container spacing={1}>
        {orders.map((order) => (
            <Grid key={order.summary.internal_order_ref} item xs={12} sm={12} md={6} lg={6} xl={3}>
            <OrderCard order={order} company_id={props.company_id}/> 
            </Grid>
        ))}
        </Grid>
        </div>
    )
}


class Orders extends Component {

    constructor(props) {
        super(props);
        const { match: { params } } = this.props;

        const session = Session.get();
        const { payload } = Session.get();

        // console.log(session.isValid); // will be true if is not expired or innactive
        // console.log(payload); // anything you have set on the session payload is stored here

        if (!session || !session.isValid) {
          this.props.history.push("/");
        }

        this.state = {
          orders: [],
          page_number: 0,
          company_name: "",
          company_id: params.companyid,
          search_query: "",
          sort_option: "last_updated",
          token: payload.token
        };
      }

      loadOrdersFromServer() {
        this.setState({orders: []}, ()=> {
          let data = {
            method: 'POST',
            body: JSON.stringify({
              company: this.state.company_id,
              auth_token: this.state.token,
              num_records: this.props.num_records,
              page_number: this.state.page_number,
              search_query: this.state.search_query,
              sort_option: this.state.sort_option
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-API-KEY': process.env.REACT_APP_API_KEY
            }
        }
  
          fetch(process.env.REACT_APP_API_URL + '/orders/', data)
          .then((response) => {
            if(!response.ok) {
              Session.destroy();
              this.props.history.push('/');
            } 
            return response.json();
        })
            .then(data => this.setState({ orders: data.orders,
          total_records:  data.total_count,
          company_name: data.company_name
       }));

        })

        

      }

      handleSelect = (e) => {
        this.setState({sort_option: e.target.value,
          page_number: 0}, () => {
          this.loadOrdersFromServer();
        })
      }

      handleInput = (e) => {
        this.setState({search_query: e.target.value, page_number: 0})
        if (this.props.onChange) {
          this.props.onChange(e.target.value)
        }
      }

      handleKeyUp = (e) => {
        if (e.charCode === 13 || e.key === 'Enter') {
          this.loadOrdersFromServer()
        }
        if (this.props.onKeyUp) {
          this.props.onKeyUp(e)
        }
      }

      componentDidMount() {
        this.loadOrdersFromServer();
      }

      handleClick(offset) {

    
        this.setState({ page_number: Math.ceil(offset /  this.props.num_records)}, () => {
          this.loadOrdersFromServer();
        });
      };

      render() {
 
        
        const { orders } = this.state;

        return (
             <div>
                   
                   <CustomizedInputBase 
                   handleSearch={() => this.loadOrdersFromServer()} 
                   handleInput={this.handleInput}
                   handleKeyUp={this.handleKeyUp}/>
                   <SortSelector handleSort={this.handleSelect} sort_option={this.state.sort_option} />
                   
             <OrderCards orders={orders} company_id={this.state.company_id}/>
             <Pagination
          limit={this.props.num_records}
          offset={this.state.page_number*this.props.num_records}
          total={this.state.total_records}
          onClick={(e, offset) => this.handleClick(offset)}
        /></div>
        );
      }
}

export default Orders