import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Session} from 'bc-react-session';


const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgot: {
    float: 'right',
    marginTop: '1rem'
  }
});

class SignIn extends Component {

    

    constructor(props) {
        super(props);

        this.state = {
          email: "",
          password: "",
          open: false,
        };

        
      }

    handleSubmit = (event) => {
        event.preventDefault();
        let data = {
            method: 'POST',
            body: JSON.stringify({
                email_address: this.state.email,
                password: this.state.password
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        fetch(process.env.REACT_APP_API_URL + "/userauth/login", data)
        .then((response) => {
            if(!response.ok) {
              this.setState({
                open: true
              });
                throw Error(response.statusText);
            } 
            return response.json();
        })
        .then((responseJson) => {

                Session.start({ 
                  payload: {
                      loggedin: true,
                      company: responseJson.company,
                      token: responseJson.token
                  },
                  expiration: 86400000, // (optional) defaults to 1 day
                });
                this.props.history.push('/'+responseJson.company);
            })
        .catch((error) => {
            console.error(error);
        });
    }
  
    handleChange = event => {
      this.setState({
        [event.target.name]: event.target.value
      });
    };

    handleClick = () => {
      this.setState({
        open: true
      });
    };
  
    handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      this.setState({
        open: false
      });
    };
   

  render() {
    const {classes} = this.props;
    const { email, password } = this.state;

    return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Login Failed</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            Sign in
            </Typography>
            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
            <TextField
               
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={this.handleChange}
                
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={this.handleChange}

            />
            <Link className={classes.forgot} color="inherit" href="/auth/forgottenpassword">
              Forgot your password?
            </Link>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Sign In
            </Button>
            
            
        </form>
        </div>
        </Container>
    );
    }
}

export default withStyles(useStyles)(SignIn)