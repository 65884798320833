import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/flag-icon.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Orders from './Orders';
import Companies from './Companies';
import HeaderBar from './Header';
import * as serviceWorker from './serviceWorker';
import SignIn from './SignIn';
import Reset from './Reset';
import ForgottenPassword from './ForgottenPassword';
import ChangePassword from './ChangePassword';
import Footer from './Footer';
const routing = (
    <div>
    <BrowserRouter>
    
      <div>
      <HeaderBar/>
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route exact path="/companies" component={Companies} />
        <Route exact path="/:companyid" render={(props) => <Orders {...props} num_records={10}/>}/>
        <Route exact path="/auth/reset/:resettoken" render={(props) => <Reset {...props}/>}/>
        <Route exact path="/auth/changepassword" component={ChangePassword} />
        <Route exact path="/auth/forgottenpassword" render={(props) => <ForgottenPassword {...props}/>}/>
        </Switch>
      <Footer/>
      </div>
    </BrowserRouter></div>
  )
  ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
