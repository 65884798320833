import React, {Component } from 'react'
import {Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Pagination from "material-ui-flat-pagination";
import { deepOrange } from '@material-ui/core/colors';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const useStyles = makeStyles(theme => ({
    hroot: {
      flexGrow: 1,
    },
    paper: {
        
        padding: theme.spacing(1, 2),
      },

    
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    root: {
      padding: theme.spacing(3, 2),
    },
    chip: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    card: {
      minWidth: 275,
      margin: theme.spacing(3,2),
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    orangeAvatar: {
      marginRight: 10,
      width: "35px",
      height: "35px",
      color: '#fff',
      backgroundColor: deepOrange[800],
      float: 'left',
    },
    cardTitle: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    }
  }));



function CompanyCard(props) {
    const company = props.company;
    const classes = useStyles();
    return (
        <Card className={classes.card}>
                <CardContent>
                <br /><Avatar className={classes.orangeAvatar}>{company.company_readable[0]}</Avatar>
                <Typography variant="h5" component="h2">
                {company.company_readable}
                  </Typography>
                  
                  
                </CardContent>
                <CardActions>
                  <Button size="small"><Link to={'/' + company.company }>View Orders></Link></Button>
                </CardActions>
              </Card>
    )
}

function CompanyCards(props) {
    const companies = props.companies;
    return (
        <div>
        {companies.map((company) => (
            <CompanyCard key={company.company} company={company}/> 
        ))}
        </div>
    )
}

function Breadcrumb(props){
    const classes = useStyles();
    return (
    <Paper elevation={0} className={classes.paper}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Home</Typography>
          </Breadcrumbs>
        </Paper>
    )
  }

class Companies extends Component {

    constructor(props) {
        super(props);

        this.state = {
          companies: [],
          page_number: 0,
        };
      }

      loadOrdersFromServer() {
        
        fetch(process.env.REACT_APP_API_URL + '/accounts/?phone_number=%2B447903033564', {
            method: 'get',
            headers: new Headers({
                'X-API-KEY': 'hi'
            })
        })
          .then(response => response.json())
          .then(data => this.setState({ companies: data
     }));

      }

      componentDidMount() {
          this.loadOrdersFromServer();
      }

      handleClick(offset) {
        console.log(offset);
    
        this.setState({ page_number: Math.ceil(offset /  this.props.num_records)}, () => {
          this.loadOrdersFromServer();
        });
      };

      render() {
 
        
        const { companies } = this.state;
        console.log(this.state);
        return (
             <div>
                   <Breadcrumb/>
             <CompanyCards companies={companies}/>
             <Pagination
          limit={this.props.num_records}
          offset={this.state.page_number*this.props.num_records}
          total={this.state.total_records}
          onClick={(e, offset) => this.handleClick(offset)}
        /></div>
        );
      }

}

export default Companies