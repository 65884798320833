import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © Wineflow Mobile Order Tracker V1.01 '}
       

      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = (props) => {
    
    return (
      <Box mt={6}>
      <Copyright />
</Box>
    )
  }

export default Footer