import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Session} from 'bc-react-session';
import { withRouter } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ListItemIcon from '@material-ui/core/ListItemIcon';


const useStyles = makeStyles(theme => ({
    hroot: {
      flexGrow: 1,
      backgroundColor: '#a03a69'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    root: {
      padding: theme.spacing(3, 2),
    },
    chip: {
      margin: theme.spacing(1),
    },
    card: {
      minWidth: 275,
      margin: theme.spacing(3,2),
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    cardTitle: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    }
  }));

function HamburgerMenu(props) {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const session = Session.get();
    if (!session || !session.isValid) {
      return (<div></div>)
    }
    const { classes} = props;
    const signOut = () => {
      handleClose();
      Session.destroy();
      props.history.push('/');
    }
    const changePassword = () => {
      handleClose();
      props.history.push('/auth/changepassword');
    }
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
        <div>
        <IconButton aria-controls="simple-menu" edge="start" className={classes.menuButton} color="inherit" onClick={handleClick} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={signOut}><ListItemIcon><ExitToAppIcon/></ListItemIcon> Sign Out</MenuItem>
        <MenuItem onClick={changePassword}><ListItemIcon><VpnKeyIcon/></ListItemIcon> Change Password</MenuItem>
      </Menu></div>
      )
  };

const HeaderBar = (props) => {
    
    const classes = useStyles();
    

    

    

    return (
    <div>
    <header className={classes.hroot}>
    <AppBar className={classes.hroot} position="static">
      <Toolbar>
        
        <Typography variant="h6" className={classes.title}>
          Mobile Order Tracker
        </Typography>
        <HamburgerMenu {...props} classes={classes} />
      </Toolbar>
    </AppBar>
    
     
    </header>
    </div>  
    )
  }

export default withRouter(HeaderBar)